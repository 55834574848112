// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery AJAX customization.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// Theming helpers.
import Theme from '../misc/theme';
// Theming helpers.
import Translator from '../misc/translator';
// Brixx utility functions.
import brixxUtils from '../misc/brixxUtils';
// Awesomplete.
import Awesomplete from 'awesomplete';
// Awesomplete util.
import AwesompleteUtil from 'awesomplete-util';
// UI AwesompleteUtil mods.
import '../components/ui-awesompletex';
// BRIXX UI ajax handlers.
import '../components/ui-ajax';
// BRIXX forms.
import '../components/ui-forms';
// Load templates.
import '../components/ui-templates';
// Timeline components.
// import {DataSet} from 'vis-data/peer';
import {Timeline, DataSet} from '../lib/vis-timeline-graph2d.js';
// Date formatter library.
import moment from 'moment';

/**
 * Contains all scripts specific to the projects and vacation planner.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx, brixxUtils, Translator, Awesomplete, AwesompleteUtil, Theme, DataSet, Timeline, moment, window) => {

    // Whether the planner module has already been initialized.
    if (typeof Brixx.modules.planner !== 'undefined') {
        // Bail out.
        return;
    }

    const mediaQuerySmall = window.matchMedia('screen and (max-width: 767.999999px)');
    const mediaQueryMedium = window.matchMedia('screen and (min-width: 768px) and (max-width: 1023.999999px)');
    const mediaQueryFull = window.matchMedia('screen and (min-width: 1024px)');

    /**
     * Merges objects in arrays by one if its properties.
     *
     * @param {Array} original
     *   Array of objects that shall be updated.
     * @param {Array} updated
     *   Array of updated objects.
     * @param {string} [prop='id']
     *   (Optional) Name of the property by which to merge
     *   objects.
     *   Defaults to 'id'.
     */
    const mergeByProperty = (original, updated, prop) => {
        prop = prop || 'id';
        updated.forEach(sourceElement => {
            let targetElement = original.find(targetElement => {
                return sourceElement[prop] === targetElement[prop];
            });

            if (targetElement) {
                Object.assign(targetElement, {...targetElement, ...sourceElement});
            }
            else {
                original.push(sourceElement);
            }
        });
    };

    /**
     * The timeout ID for a click event prevention reset timeout.
     *
     * @type {integer|null}
     *
     * @see Brixx.modules.planner.ignoreTimelineClick()
     * @see Brixx.modules.planner.isIgnoreTimelineClick
     */
    let _ignoreTimelineClickReset = null;

    /**
     * BRIXX module for the projects and vacation planner.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.planner = {

        /**
         * The initial data for the planner timelines.
         *
         * This data is provided by the BRIXX app and will be used to initialize
         * the timelines within the BRIXX UI.
         *
         * @type {object}
         *
         * @property {integer} clientId
         *   Internal ID of the BRIXX client the planner belongs to.
         * @property {integer} plannerId
         *   Internal ID of the current planner.
         * @property {string} plannerTitle
         *   Title of the planner.
         * @property {string} plannerViewMode
         *   View mode of the planner. Currently supported view modes are
         *   'project', 'team' and 'absence'.
         * @property {object} timelines
         *   Object containing the actual timelines data keyed by their timeline
         *   key. Each timeline data is an object with the properties
         *   `items` and `grous` with the raw items and groups data, `wrapper`
         *   with the jQuery selector for the wrapping element, `options` with
         *   additional timeline options, and `groupSort` defining the groups
         *   sorting method (`false` for no sorting, a string with a group property
         *   name for flat groups, and an array with sorting properties for nested
         *   groups).
         * @property {string} monthId
         *   The period identifier of the currently viewed period.
         *   A string of the format 'YYYY_MM' with YYYY being the 4-digit
         *   year and MM the two-digit month.
         * @property {integer} year
         *   The currently viewed year.
         * @property {integer} month
         *   The currently viewed month, with January being `1`, February `2`,
         *   and so on until December `12`.
         *
         * @memberof Brixx.modules.planner
         * @name data
         *
         * @see \Brixx\Service\PlannerService::buildProjectPlannerData()
         *   For an example of timline data created by the BRIXX App.
         */
        data: {},

        /**
         * The timelines items.
         *
         * This object has a property key for each initialized and displayed
         * timeline having its bi-directional items DataSet as value.
         *
         * @type {object}
         * @memberof Brixx.modules.planner
         * @name items
         */
        items: {},

        /**
         * The timelines groups.
         *
         * This object has a property key for each initialized and displayed
         * timeline having its bi-directional groups DataSet as value.
         *
         * @type {object}
         * @memberof Brixx.modules.planner
         * @name groups
         */
        groups: {},

        /**
         * The timelines.
         *
         * This object has a property key for each initialized and displayed
         * timeline having its timeline object as value.
         *
         * @type {object}
         * @memberof Brixx.modules.planner
         * @name timelines
         */
        timelines: {},

        /**
         * Flag indicating whether to ignore timeline clicks.
         *
         * @type {boolean}
         * @memberof Brixx.modules.planner
         * @name isIgnoreTimelineClick
         */
        isIgnoreTimelineClick: false,

        /**
         * Sets the flag to ignore timeline clicks.
         *
         * The flag will be reset to false after 250ms. This is a workaround for
         * dragging events to also trigger click events without any means to
         * prevent this bubbling. As we don't want a timeline item being created
         * when the user drags the timeline, we set this flag to ignore any
         * click events immediately after dragging.
         *
         * @memberof Brixx.modules.planner
         * @method ignoreTimelineClick
         */
        ignoreTimelineClick: () => {
            if (_ignoreTimelineClickReset) {
                clearTimeout(_ignoreTimelineClickReset);
            }

            Brixx.modules.planner.isIgnoreTimelineClick = true;

            _ignoreTimelineClickReset = setTimeout(() => {
                Brixx.modules.planner.isIgnoreTimelineClick = false;
            }, 250);
        },

        /**
         * Custom sort function callback for planner timeline groups.
         *
         * Allows to specify the sort order group property by name.
         * If the name is prefixed by a `-`, the sorting will be
         * inversed from ascending to descending.
         *
         * @param {string} property
         *   Name of the group property to sort by.
         *
         * @return {function}
         *   The sorting function for groups.
         *
         * @memberof Brixx.modules.planner
         * @method groupSort
         */
        groupSort: property => {
            const properties = property.split(',');

            // Return the actual sorting function.
            return (a, b) => {
                // Neutral result by default;
                let result = 0;
                let sortOrder;
                let property;

                for (let level = 0; level < properties.length; level++) {
                    // Whether we don't have a valid property.
                    if (!properties[level]) {
                        continue;
                    }

                    property = properties[level];

                    // Sort ascending by default.
                    sortOrder = 1;
                    // Whether to sort descending.
                    if (property && property[0] === '-') {
                        // Inverse the sortorder and remove the `-` prefix from
                        // the property name.
                        sortOrder = -1;
                        property = property.substr(1);
                    }

                    // If the requested property is missing in any of the
                    // groups, return a neutral sort result.
                    if (!property || typeof a[property] === 'undefined' || typeof b[property] === 'undefined') {
                        continue;
                    }

                    // Use locale aware multibyte string comparision with
                    // natural numeric sort to determine the comparision
                    // flag.
                    result = a[property].toString().replace(/<(.|\n)*?>/g, '').localeCompare(b[property].toString().replace(/<(.|\n)*?>/g, ''), Brixx.settings.locale, {numeric: true});

                    // Whether we have a non-neutral result.
                    if (result !== 0) {
                        // Return the result with possible inversion of the
                        // order.
                        return result * sortOrder;
                    }
                }

                // Return the (neutral) result.
                return result;
            };
        },

        /**
         * Custom sort function callback for nested planner timeline groups.
         *
         * This function allows to sort different nesting levels using
         * different properties by name.
         *
         * If groups with different nesting levels are given, the groups will
         * be sorted descending by nesting level.
         *
         * @param {Array} properties
         *   The property names to sort nested groups by. The property names are
         *   applied in the given order of this array. The first given property
         *   name parameter is applied to nesting level 0, the second parameter
         *   to nesting level 1, and so on. If a property name is prefixed by
         *   `-`, the sorting of this property will be inversed from ascending to
         *   descending.
         *
         * @return {function}
         *   The sorting function for nested groups.
         *
         * @memberof Brixx.modules.planner
         * @method groupSortNested
         */
        groupSortNested: properties => {
            const propertiesLength = Array.isArray(properties) ? properties.length : 0;

            // Return the actual sorting function.
            return (a, b) => {
                // Whether no or invalid properties were given.
                if (propertiesLength === 0) {
                    // Do not alter sorting.
                    return 0;
                }
                // Whether the given groups aren't nesting groups.
                if (typeof a['treeLevel'] === 'undefined' || typeof b['treeLevel'] === 'undefined') {
                    // Apply regular sort function using the first
                    // given property name.
                    return Brixx.modules.planner.groupSort(properties[0])(a, b);
                }
                // Whether the given groups are of different
                // nesting level.
                if (a['treeLevel'] !== b['treeLevel']) {
                    // Sort the child levels before all parents. This ensures
                    // that the children are known to the timeline before
                    // their parents with child information are rendered.
                    return b['treeLevel'] - a['treeLevel'];
                }
                // Whether the nesting level exceeds the amount of given
                // property names.
                if (a['treeLevel'] > propertiesLength) {
                    // Do not alter sorting.
                    return 0;
                }
                // Apply sorting according to the given property name.
                return Brixx.modules.planner.groupSort(properties[a['treeLevel']])(a, b);
            };
        },

        groupDragInfo: {
            source: null,
            sourceKey: null,
            indicator: null
        },

        groupDragInfoReset: () => {
            $('.panning').removeClass('panning');
            $('.vis-group-is-dragging').removeClass('vis-group-is-dragging');

            Brixx.modules.planner.groupDragSortTargetIndicatorRemove();

            if (Brixx.modules.planner.groupDragInfo.indicator) {
                $(Brixx.modules.planner.groupDragInfo.indicator).remove();
                Brixx.modules.planner.groupDragInfo.source = null;
                Brixx.modules.planner.groupDragInfo.sourceKey = null;
            }
        },

        groupDragStart: key => {
            return event => {
                // Get a jQuery object of the tapped element.
                const $firstTarget = $(event.firstTarget);

                // Determine draggable group.
                const $source = $firstTarget.hasClass('is-draggable') ? $firstTarget : $firstTarget.closest('.is-draggable');

                // Whether no draggable group was identified or the `event.center` property is missing.
                if (!$source.length || typeof event.center === 'undefined') {
                    // Reset drag info and bail out.
                    Brixx.modules.planner.groupDragInfoReset();
                    return;
                }

                // Whether to create a group drag indicator element.
                if (!$source.hasClass('panning')) {
                    // Remove previous group drag indicator element.
                    Brixx.modules.planner.groupDragInfoReset();

                    // Copy source element.
                    Brixx.modules.planner.groupDragInfo.source = $source;
                    Brixx.modules.planner.groupDragInfo.indicator = Brixx.modules.planner.groupDragInfo.source.clone(true);
                    Brixx.modules.planner.groupDragInfo.source.addClass('panning');

                    // Apply some styles for the fixed element.
                    Brixx.modules.planner.groupDragInfo.indicator
                        .css('width', '240px')
                        .css('padding', '0 10px')
                        .css('height', '31px')
                        .css('line-height', '31px')
                        .css('background', '#efefef')
                        .css('border', 'solid 1px #ddd')
                        .css('opacity', '.75')
                        .css('z-index', '1100')
                        .removeClass('vis-nesting-group vis-nested-group expanded vis-group-level-0 vis-group-level-1 vis-group-level-2');

                    // Add the drag indicator element to the DOM.
                    $('body').append(Brixx.modules.planner.groupDragInfo.indicator);
                    // Remove a few elements from the drag indicator.
                    $('.short-label, .planner-group-actions, .progress', Brixx.modules.planner.groupDragInfo.indicator).remove();

                    // Extract timeline group info from the source element.
                    Brixx.modules.planner.groupDragInfo.sourceKey = brixxUtils.extractIdFromClasses(/timeline-key-(.*)/, $source.attr('class'));
                    const sourceGroupId = brixxUtils.extractIdFromClasses(/timeline-group-(.*)/, $source.attr('class'));
                    Brixx.modules.planner.groupDragInfo.source = (typeof Brixx.modules.planner.groups[Brixx.modules.planner.groupDragInfo.sourceKey] !== 'undefined') ? Brixx.modules.planner.groups[Brixx.modules.planner.groupDragInfo.sourceKey].get(sourceGroupId) : null;
                }
            };
        },

        groupDragSortGetTargetGroup: event => {
            let targetGroup = null;

            // Whether the drag source doesn't support drag sort.
            if (
                // Whether no valid source group key was found on drag start.
                !Brixx.modules.planner.groupDragInfo.sourceKey
                // Whether no valid source group was found on drag start.
                || !Brixx.modules.planner.groupDragInfo.source
                // Whether the source group does not support drag sort.
                || !Brixx.modules.planner.data.timelines[Brixx.modules.planner.groupDragInfo.sourceKey].groupDragSort
                // Whether a certain tree level is required for drag sort and the drag source doesn't meet this requirement.
                || (typeof Brixx.modules.planner.data.timelines[Brixx.modules.planner.groupDragInfo.sourceKey].groupDragSort.treeLevel !== 'undefined' && (typeof Brixx.modules.planner.groupDragInfo.source.treeLevel === 'undefined' || Brixx.modules.planner.data.timelines[Brixx.modules.planner.groupDragInfo.sourceKey].groupDragSort.treeLevel !== Brixx.modules.planner.groupDragInfo.source.treeLevel))
            ) {
                return null;
            }

            // Determine the current target element. We allow sorting the
            // same timeline only. So we can safely try getting the group
            // from the source timeline.
            const currentGroup = Brixx.modules.planner.timelines[Brixx.modules.planner.groupDragInfo.sourceKey].itemSet.groupFromTarget(event);
            // Whether the current target isn't a group in the source
            // timeline.
            if (!currentGroup || typeof currentGroup.groupId === 'undefined') {
                return null;
            }

            // Extract the drag sort target group ID from the current
            // group CSS classes
            const targetGroupId = brixxUtils.extractIdFromClasses(Brixx.modules.planner.data.timelines[Brixx.modules.planner.groupDragInfo.sourceKey].groupDragSort.targetGroupClassPattern, currentGroup.dom.label.className);
            if (!targetGroupId) {
                return null;
            }

            targetGroup = Brixx.modules.planner.groups[Brixx.modules.planner.groupDragInfo.sourceKey].get(targetGroupId);
            // Whether no target group could be found or the target group
            // is of different treeLevel than the allowed level.
            if (!targetGroup || (typeof Brixx.modules.planner.data.timelines[Brixx.modules.planner.groupDragInfo.sourceKey].groupDragSort.treeLevel !== 'undefined' && (typeof targetGroup.treeLevel === 'undefined' || Brixx.modules.planner.data.timelines[Brixx.modules.planner.groupDragInfo.sourceKey].groupDragSort.treeLevel !== targetGroup.treeLevel))) {
                targetGroup = null;
            }

            return targetGroup;
        },

        groupDragSortApply: (timelineKey, sourceGroup, targetGroup) => {
            // Get all group IDs in current sort order.
            let filterOptions = {
                filter: item => {
                    return item.id !== 'timeline-empty' && (typeof Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.treeLevel === 'undefined' || item.treeLevel === Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.treeLevel);
                }
            };
            if (typeof Brixx.modules.planner.data.timelines[timelineKey].groupSort !== 'undefined' && Brixx.modules.planner.data.timelines[timelineKey].groupSort) {
                filterOptions = {...filterOptions, ...{
                    order: Array.isArray(Brixx.modules.planner.data.timelines[timelineKey].groupSort) ? (Brixx.modules.planner.groupSortNested(Brixx.modules.planner.data.timelines[timelineKey].groupSort)) : (Brixx.modules.planner.groupSort(Brixx.modules.planner.data.timelines[timelineKey].groupSort))
                }};
            }
            const groupIds = [...Brixx.modules.planner.groups[timelineKey].getIds(filterOptions)];

            let targetIndex = groupIds.indexOf(targetGroup.id);
            let sourceIndex = groupIds.indexOf(sourceGroup.id);
            if (targetIndex < 0 || sourceIndex < 0 || targetIndex === sourceIndex) {
                return;
            }

            // Now do the actual swapping starting with the source group.
            // @todo This code is still hard-coded to sortorder and callback. Refactor!
            const property = Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.property;

            let oldPropertyValue;
            let dragSource = Brixx.modules.planner.groups[timelineKey].get(groupIds[sourceIndex]);
            let dragTarget;
            let changedGroups = [];
            let i;

            // Whether swapping was upwards.
            if (targetIndex < sourceIndex) {
                for (i = sourceIndex - 1; i >= targetIndex; i--) {
                    dragTarget = Brixx.modules.planner.groups[timelineKey].get(groupIds[i]);
                    oldPropertyValue = dragSource[property];
                    dragSource[property] = dragTarget[property];
                    dragTarget[property] = oldPropertyValue;
                    Brixx.modules.planner.groups[timelineKey].update(dragSource);
                    Brixx.modules.planner.groups[timelineKey].update(dragTarget);
                    changedGroups.push(dragTarget);
                }
            }
            // Dragging was downwards.
            else {
                for (i = sourceIndex + 1; i <= targetIndex; i++) {
                    dragTarget = Brixx.modules.planner.groups[timelineKey].get(groupIds[i]);
                    oldPropertyValue = dragSource[property];
                    dragSource[property] = dragTarget[property];
                    dragTarget[property] = oldPropertyValue;
                    Brixx.modules.planner.groups[timelineKey].update(dragSource);
                    Brixx.modules.planner.groups[timelineKey].update(dragTarget);
                    changedGroups.push(dragTarget);
                }
            }

            changedGroups.push(dragSource);

            // Update unprocessed timeline groups with sortorder changes.
            mergeByProperty(Brixx.modules.planner.data.timelines[timelineKey].groups, changedGroups);
            // Apply groups sorting to unprocessed groups data, to match
            // groups sorting in timelines.
            if (typeof Brixx.modules.planner.data.timelines[timelineKey].groupSort !== 'undefined' && Brixx.modules.planner.data.timelines[timelineKey].groupSort) {
                Brixx.modules.planner.data.timelines[timelineKey].groups.sort(Array.isArray(Brixx.modules.planner.data.timelines[timelineKey].groupSort) ? Brixx.modules.planner.groupSortNested(Brixx.modules.planner.data.timelines[timelineKey].groupSort) : Brixx.modules.planner.groupSort(Brixx.modules.planner.data.timelines[timelineKey].groupSort));
            }

            // Send changed data to BRIXX App.
            if (typeof Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.callbackUrl !== 'undefined' && Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.callbackUrl) {
                const changedData = {};
                changedGroups.forEach(group => {
                    changedData[group.id] = group[property];
                });
                const data = new FormData();
                data.append(property, JSON.stringify(changedData));

                Brixx.modules.planner.ajaxBrixxRequest(Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.callbackUrl, data, false);
            }
        },

        groupDragSortTargetIndicatorAdd: (timelineKey, sourceGroup, targetGroup) => {
            // Get all group IDs in current sort order
            let filterOptions = {
                filter: item => {
                    return item.id !== 'timeline-empty' && (typeof Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.treeLevel === 'undefined' || item.treeLevel === Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.treeLevel);
                }
            };
            if (typeof Brixx.modules.planner.data.timelines[timelineKey].groupSort !== 'undefined' && Brixx.modules.planner.data.timelines[timelineKey].groupSort) {
                filterOptions = {...filterOptions, ...{
                    order: Array.isArray(Brixx.modules.planner.data.timelines[timelineKey].groupSort) ? (Brixx.modules.planner.groupSortNested(Brixx.modules.planner.data.timelines[timelineKey].groupSort)) : (Brixx.modules.planner.groupSort(Brixx.modules.planner.data.timelines[timelineKey].groupSort))
                }};
            }
            const groupIds = [...Brixx.modules.planner.groups[timelineKey].getIds(filterOptions)];

            let targetIndex = groupIds.indexOf(targetGroup.id);
            let sourceIndex = groupIds.indexOf(sourceGroup.id);
            if (targetIndex < 0 || sourceIndex < 0 || targetIndex === sourceIndex) {
                return;
            }

            // Determine indicator group class.
            let targetClass = '';
            let classes = targetGroup.className.toString().split(' ');
            let i;
            for (i = 0; i < classes.length; i++) {
                if (brixxUtils.checkRegExp(classes[i], Brixx.modules.planner.data.timelines[timelineKey].groupDragSort.targetGroupClassPattern)) {
                    targetClass = '.' + classes[i];
                    break;
                }
            }

            // Drag direction up.
            if (targetIndex < sourceIndex) {
                $('.vis-labelset .vis-label' + targetClass).first().addClass('drop-target-up');
            }
            // Drag direction down.
            else {
                $('.vis-labelset .vis-label' + targetClass).last().addClass('drop-target-down');
            }
        },

        groupDragSortTargetIndicatorRemove: () => {
            $('.vis-labelset .vis-label.drop-target-up').removeClass('drop-target-up');
            $('.vis-labelset .vis-label.drop-target-down').removeClass('drop-target-down');
        },

        groupDragMove: key => {
            return event => {
                // Whether to update the group pan indicator element position.
                if (typeof event.center !== 'undefined' && Brixx.modules.planner.groupDragInfo.indicator) {
                    Brixx.modules.planner.groupDragInfo.indicator.css('position', 'fixed').css('left', (event.center.x + 5) + 'px').css('top', (event.center.y + 5) + 'px');
                }

                // Remove any existing drop sort target indicator classes.
                Brixx.modules.planner.groupDragSortTargetIndicatorRemove();

                // Whether a valid drag sort action is undergoing.
                const timelineKey = Brixx.modules.planner.groupDragInfo.sourceKey;
                const sourceGroup = Brixx.modules.planner.groupDragInfo.source;
                const targetGroup = Brixx.modules.planner.groupDragSortGetTargetGroup(event);
                if (!timelineKey || !sourceGroup || !targetGroup) {
                    return;
                }

                // Add drop sort target indicator classes.
                Brixx.modules.planner.groupDragSortTargetIndicatorAdd(timelineKey, sourceGroup, targetGroup);
            };
        },

        groupDragCancel: key => {
            return event => {
                Brixx.modules.planner.groupDragInfoReset();
            };
        },

        groupDragEnd: key => {
            return event => {
                // Whether a valid drag sort action is undergoing.
                const sourceTimelineKey = Brixx.modules.planner.groupDragInfo.sourceKey;
                const sourceGroup = Brixx.modules.planner.groupDragInfo.source;
                const dragSortTargetGroup = Brixx.modules.planner.groupDragSortGetTargetGroup(event);

                // Remove panning indicator element from DOM.
                Brixx.modules.planner.groupDragInfoReset();

                if (!sourceTimelineKey || !sourceGroup) {
                    return;
                }

                // Whether we have a drag sort target.
                if (dragSortTargetGroup) {
                    Brixx.modules.planner.groupDragSortApply(sourceTimelineKey, sourceGroup, dragSortTargetGroup);
                    // Don't drag sort AND add members. Bail out now!
                    return;
                }

                // Whether we don't have a related team member ID in
                // the source group.
                if (typeof sourceGroup.teamMember === 'undefined') {
                    // Bail out.
                    return;
                }

                const teamMemberId = sourceGroup.teamMember;

                // Determine target group element. We allow dragging into any group
                // with a `teamDropGroup` property of any visible timelines. So let's
                // check all timelines to determine a possible target group.
                let dropTarget;
                let dropTargetGroup;
                let targetKey;
                const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);
                // Loop through the timeline keys until we have a group match.
                timelineKeys.some(timelineKey => {
                    // Determine the window-relative top and bottom position
                    // of the timeline.
                    const windowScrollTop = $(window).scrollTop();
                    const $timelineContainer = $(Brixx.modules.planner.timelines[timelineKey].dom.container);
                    const timelineTop = ($timelineContainer.offset())['top'] - windowScrollTop;
                    const timelineBottom = timelineTop + $timelineContainer.outerHeight();

                    // Whether the event doesn't have a center position or its y
                    // coordinate is outside of the timeline top and bottom
                    // position. - This check is to prevent the timeline from reporting
                    // its last group as drop target, if the drop position is outside
                    // the timeline.
                    if (!event.center || event.center.y < timelineTop || event.center.y > timelineBottom) {
                        // Bail out.
                        return false;
                    }

                    dropTarget = Brixx.modules.planner.timelines[timelineKey].itemSet.groupFromTarget(event);
                    // Whether there is a target group in this timeline.
                    if (dropTarget && typeof dropTarget.groupId !== 'undefined') {
                        // Remember the timeline key.
                        targetKey = timelineKey;
                        // Bail out.
                        return true;
                    }
                    return false;
                });

                // Whether there is no drop target group or it doesn't have a `groupId`
                // property that allows us fetching the underlying group from the
                // DataSet.
                if (!dropTarget || typeof dropTarget.groupId === 'undefined') {
                    // Bail out.
                    return;
                }

                // Get the underlying target group information from the DataSet.
                dropTargetGroup = Brixx.modules.planner.groups[targetKey].get(dropTarget.groupId);
                // An allowed target group has a `teamDropGroup` property referencing
                // the group ID of the actual group where the member is to be added.
                // Whether there's no matching group in the DataSet or the matching
                // group doesn't allow dropping team members.
                if (!dropTargetGroup || typeof dropTargetGroup.teamDropGroup === 'undefined') {
                    // Bail out.
                    return;
                }

                // Get the actual target group information.
                const targetGroup = Brixx.modules.planner.groups[targetKey].get(dropTargetGroup.teamDropGroup);
                // Whether no matching target group was found.
                if (!targetGroup) {
                    // Bail out.
                    return;
                }

                // Add the team member to the group.
                Brixx.modules.planner.teamDropApply(targetGroup, teamMemberId);
            };
        },

        /**
         * Closes all planner modal dialogs.
         *
         * @memberof Brixx.modules.planner
         * @method closePlannerModals
         */
        closePlannerModals: () => {
            $('.modal-popup').each((index, modal) => {
                $(modal).foundation('close');
            });
        },

        /**
         * Returns a timeline template.
         *
         * This is a wrapper function for the Theme templates that accepts
         * timeline group and item objects as parameters, which hold a
         * `template` property with the actual template name.
         *
         * If there is no `template` property or the BRIXX Theme helper did
         * not find a template with the given name, the contents of the group
         * or items' `content` property will be returned.
         *
         * @param {object} item
         *   The item to style. Can be a timeline group or a timeline item.
         * @param {object} element
         *   The rendered element.
         * @param {object} data
         *   Additional data.
         *
         * @return {String|function|DOMElement}
         *   The rendered item or a markup string.
         *
         * @memberof Brixx.modules.planner
         * @method template
         */
        template: (item, element, data) => {
            if (!item) {
                return '';
            }

            if (typeof item.template !== 'undefined') {
                if (Object.prototype.hasOwnProperty.call(Theme.templates, item.template)) {
                    return Theme.templates[item.template](item);
                }

                return item.content;
            }

            return item.content;
        },

        /**
         * Returns a timeline axis template.
         *
         * This is a wrapper function for the Theme templates that accepts
         * timeline axis information as composed by the custom timeline
         * options' `format.majorLabels` callback.
         *
         * If the BRIXX Theme helper did not find a template with the given
         * name, the contents of the `axisData.date` property will be returned
         * formatted as a date.
         *
         * @param {string} templateName
         *   The axis template handlebars identifier.
         * @param {object} axisData
         *   Additional data.
         *
         * @return {String|function|DOMElement}
         *   The rendered item or a markup string.
         *
         * @memberof Brixx.modules.planner
         * @method axisTemplate
         */
        axisTemplate: (templateName, axisData) => {
            if (Object.prototype.hasOwnProperty.call(Theme.templates, templateName)) {
                return Theme.templates[templateName](axisData);
            }

            return (typeof axisData.date !== 'undefined' && !isNaN(axisData.date)) ? brixxUtils.dateFormat(axisData.date.toDate(), 'YYYY MMM') : '';
        },

        /**
         * Updates the timeline data.
         *
         * This function expects a COMPLETE set of timeline data as provided
         * for initialization of the timelines.
         *
         * @param {object} data
         *   The timeline data with `groups` and `items` properties.
         * @param {boolean|null} [rebuildTimelines=false]
         *   Whether to destroy existing timelines and fully rebuild them
         *   with the given data (`true`), or update the underlying
         *   groups and items DataSets of the timelines only and redraw
         *   the corresponding timelines (`false`).
         *   Some data updates as newly added nested groups may require a
         *   full rebuild due to some bugs with nested groups in the timeline
         *   module. Newly added items should be save to use with a redraw
         *   only.
         *   Defaults to `false`.
         *
         * @memberof Brixx.modules.planner
         * @method updateData
         */
        updateData: (data, rebuildTimelines) => {
            rebuildTimelines = rebuildTimelines || false;

            Brixx.modules.planner.data = data;
            if (!rebuildTimelines && typeof Brixx.modules.planner.data.timelines !== 'undefined') {
                const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);
                timelineKeys.forEach(key => {
                    Brixx.modules.planner.groups[key].update(Brixx.modules.planner.data.timelines[key].groups);
                    Brixx.modules.planner.items[key].update(Brixx.modules.planner.data.timelines[key].items);
                    if (typeof Brixx.modules.planner.timelines[key] !== 'undefined') {
                        Brixx.modules.planner.timelines[key].redraw();
                    }
                });
                return;
            }

            Brixx.modules.planner.rebuildTimelines(true);
        },

        /**
         * Destroys the timelines and re-creates them with the current timeline data.
         *
         * @param {boolean|null} [renewDataSet=false]
         *   (Optional) Whether to recreate the timeline DataSets as well.
         *   Defaults to `false`.
         *
         * @memberof Brixx.modules.planner
         * @method rebuildTimelines
         */
        rebuildTimelines: (renewDataSet) => {
            renewDataSet = renewDataSet || false;

            const $updateWrapper = $('#planner-update');
            $updateWrapper.css('position', 'absolute').css('z-index', '-1000');

            // Define the visible month date range.
            let date;
            let firstDay;

            if (Brixx.modules.planner.data.mode === 'y') {
                date = (typeof Brixx.modules.planner.data.year !== 'undefined') ? new Date(Brixx.modules.planner.data.year, 0, 1) : new Date();
                firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                firstDay.setHours(0, 0, 0, 0);
            }
            else {
                date = (typeof Brixx.modules.planner.data.year !== 'undefined' && typeof Brixx.modules.planner.data.month !== 'undefined') ? new Date(Brixx.modules.planner.data.year, Brixx.modules.planner.data.month - 1, 1) : new Date();
                firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                firstDay.setHours(0, 0, 0, 0);
            }

            const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);
            timelineKeys.forEach(key => {
                const $updateElement = $('<div>', {
                    class: 'update-wrapper update-wrapper-' + key
                });
                $updateElement.appendTo($updateWrapper);
                const element = $updateElement[0];

                const timelineInfo = Brixx.modules.planner.data.timelines[key];
                // Define the visible month date range.
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                // Whether to re-initialize the underlying DataSets.
                if (renewDataSet) {
                    Brixx.modules.planner.groups[key] = new DataSet(timelineInfo.groups);
                    Brixx.modules.planner.items[key] = new DataSet(timelineInfo.items);
                }

                // Specify options.
                const options = {...Brixx.modules.planner.options, ...(timelineInfo.options || {})};

                // Indicate previous days using a background item.
                if (today.valueOf() > options.start.valueOf()) {
                    Brixx.modules.planner.items[key].add({
                        type: 'background',
                        start: new Date(options.start),
                        end: new Date((today.valueOf() - 1) <= options.end.valueOf() ? today.valueOf() - 1 : options.end.valueOf()),
                        style: 'background-color: rgba(225, 225, 225, .25); pointer-events: none;'
                    });
                }
                // Indicate holidays.
                if (typeof Brixx.modules.planner.data.holidays !== 'undefined') {
                    let holidayStart;
                    let holidayEnd;

                    $.each(Brixx.modules.planner.data.holidays, (index, holiday) => {
                        holidayStart = new Date(holiday.start);
                        holidayStart.setHours(0, 0, 0, 0);
                        holidayEnd = new Date(holiday.end);
                        holidayEnd.setHours(23, 59, 59, 0);
                        Brixx.modules.planner.items[key].add({
                            type: 'background',
                            start: holidayStart,
                            end: holidayEnd,
                            title: holiday.title,
                            style: 'background-color:' + holiday.color + ';pointer-events: none;'
                        });
                    });
                }

                // Holds the initialized timeline element until we move it to
                // our `Brixx.modules.timelines[key]` property.
                let timeline;

                // Add a callback to the options that will do the actual replacement
                // of the drawn timeline.
                options.onInitialDrawComplete = () => {
                    const $wrapper = $(timelineInfo.wrapper);
                    const $oldTimeline = $('.vis-timeline', $wrapper);
                    const oldTimeline = Brixx.modules.planner.timelines[key];
                    const $timeline = $('.vis-timeline', $updateElement);

                    if ($oldTimeline.length) {
                        $oldTimeline.css('position', 'absolute').css('z-index', '-500').fadeOut(250, () => {
                            if (oldTimeline) {
                                oldTimeline.destroy();
                            }
                            $oldTimeline.remove();
                        });
                    }

                    $timeline.detach().appendTo($wrapper);
                    $updateElement.remove();

                    timeline.dom.container = $wrapper[0];

                    Brixx.modules.planner.timelines[key] = timeline;
                    Brixx.modules.planner.timelines[key].redraw();
                };

                timeline = new Timeline(element, Brixx.modules.planner.items[key], Brixx.modules.planner.groups[key], options);
                Brixx.modules.planner.initTimelineEvents(element, timeline, key);
            });
        },

        /**
         * Updates the timeline data with partial update information.
         *
         * This method will alter/extend the bi-directional groups/items
         * DataSets of the visible timelines and the source data in
         * {@link Brixx.modules.planner.data}. To allow for redraw, or
         * complete reset/rebuild.
         *
         * @param {object} data
         *   The partial timeline update data. Supported are the three
         *   properties `add`, `update` and `delete`. Each of those properties
         *   may hold an object with any or both of the properties `groups` and
         *   `items`, which in turn hold an object with a property named after
         *   the timeline key that bears the actual update information in an
         *   array of items/groups for the `add` and `update`, or item/group
         *   IDs for the delete updates.
         * @param {boolean} [forceFullRebuild=false]
         *   Whether a full rebuild of the changed timeline(s) shall
         *   be triggered.
         *   Defaults to false.
         */
        partialDataUpdate: (data, forceFullRebuild) => {
            // Whether the provided update data is empty.
            if (!data) {
                // Bail out.
                return;
            }

            // Whether to fully rebuild the timeline(s) by destroying
            // all timeline instances and recreating them based on
            // the information in Brixx.modules.planner.data.timelines.
            let fullRebuild = forceFullRebuild || false;

            // Get available timeline keys from initial planner data.
            const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);

            // Loop through the available timeline keys.
            timelineKeys.forEach(key => {
                // Disable instant refresh and use the DataSet queues
                // for collecting pending changes.
                Brixx.modules.planner.groups[key].setOptions({queue: true});
                Brixx.modules.planner.items[key].setOptions({queue: true});

                // Whether any data shall be removed.
                if (typeof data.delete !== 'undefined') {
                    // Whether items of the current timeline shall be removed.
                    if (typeof data.delete.items !== 'undefined' && typeof data.delete.items[key] !== 'undefined') {
                        // Delete receives an array of item IDs. Loop through
                        // all item IDs.
                        $.each(data.delete.items[key], (index, item) => {
                            // Delete item from the items DataSet.
                            Brixx.modules.planner.items[key].remove(item);
                            // Delete item from unprocessed items data.
                            Brixx.modules.planner.data.timelines[key].items = Brixx.modules.planner.data.timelines[key].items.filter(existing => {
                                return existing.id !== item;
                            });
                        });
                    }
                    // Whether groups of the current timeline shall be removed.
                    if (typeof data.delete.groups !== 'undefined' && typeof data.delete.groups[key] !== 'undefined') {
                        // Delete receives an array of item IDs. Loop through
                        // all group IDs.
                        $.each(data.delete.groups[key], (index, item) => {
                            // Delete the group from the groups DataSet.
                            Brixx.modules.planner.groups[key].remove(item);
                            // Delete the group from the unprocessed groups data.
                            Brixx.modules.planner.data.timelines[key].groups = Brixx.modules.planner.data.timelines[key].groups.filter(existing => {
                                return existing.id !== item;
                            });
                            // Group changes will need a full rebuild of the timeline due to
                            // nested groups timeline bugs.
                            fullRebuild = true;
                        });
                    }
                }

                // Whether any data shall be added.
                if (typeof data.add !== 'undefined') {
                    // Add receives an array of complete items.
                    if (typeof data.add.groups !== 'undefined' && typeof data.add.groups[key] !== 'undefined') {
                        $.each(data.add.groups[key], (index, item) => {
                            // Add to groups DataSet.
                            Brixx.modules.planner.groups[key].add(item);
                            // Add to unprocessed groups data.
                            Brixx.modules.planner.data.timelines[key].groups.push(item);
                            // Group changes will need a full rebuild of the timeline due to
                            // nested groups timeline bugs.
                            fullRebuild = true;
                        });
                    }
                    if (typeof data.add.items !== 'undefined' && typeof data.add.items[key] !== 'undefined') {
                        $.each(data.add.items[key], (index, item) => {
                            // Add to items DataSet.
                            Brixx.modules.planner.items[key].add(item);
                            // Add to unprocessed items data.
                            Brixx.modules.planner.data.timelines[key].items.push(item);
                        });
                    }
                }

                // Whether any data shall be updated.
                if (typeof data.update !== 'undefined') {
                    // Update receives an array of complete or partial items.
                    if (typeof data.update.groups !== 'undefined' && typeof data.update.groups[key] !== 'undefined') {
                        $.each(data.update.groups[key], (index, item) => {
                            // Update groups DataSet.
                            Brixx.modules.planner.groups[key].update(item);
                            // Update unprocessed groups data.
                            mergeByProperty(Brixx.modules.planner.data.timelines[key].groups, [item]);
                            // Group changes will need a full rebuild of the timeline due to
                            // nested groups timeline bugs.
                            fullRebuild = true;
                        });
                    }
                    if (typeof data.update.items !== 'undefined' && typeof data.update.items[key] !== 'undefined') {
                        $.each(data.update.items[key], (index, item) => {
                            // Update items DataSet.
                            Brixx.modules.planner.items[key].update(item);
                            // Update unprocessed items data.
                            mergeByProperty(Brixx.modules.planner.data.timelines[key].items, [item]);
                        });
                    }
                }

                // Apply groups sorting to unprocessed groups data, to match
                // (the expected) groups sorting in timelines.
                if (typeof Brixx.modules.planner.data.timelines[key].groupSort !== 'undefined' && Brixx.modules.planner.data.timelines[key].groupSort) {
                    Brixx.modules.planner.data.timelines[key].groups.sort(Array.isArray(Brixx.modules.planner.data.timelines[key].groupSort) ? Brixx.modules.planner.groupSortNested(Brixx.modules.planner.data.timelines[key].groupSort) : Brixx.modules.planner.groupSort(Brixx.modules.planner.data.timelines[key].groupSort));
                }

                // Apply changes and disable DataSet queues.
                Brixx.modules.planner.groups[key].flush();
                Brixx.modules.planner.groups[key].setOptions({queue: false});
                Brixx.modules.planner.items[key].flush();
                Brixx.modules.planner.items[key].setOptions({queue: false});

                // Set 'timeline-empty' element visibility state, if required.
                // The 'timeline-empty' group is a special placeholder for
                // timelines without any visible groups (because the user may have
                // deleted them or didn't create any), and shall be visible,
                // if no other group exists, and invsible, if other groups exist.
                const group = Brixx.modules.planner.groups[key].get('timeline-empty');
                if (group && ((Brixx.modules.planner.groups[key].length === 1 && !group.visible) || (Brixx.modules.planner.groups[key].length > 1 && group.visible))) {
                    group.visible = (Brixx.modules.planner.groups[key].length === 1);
                    Brixx.modules.planner.groups[key].update(group);
                    mergeByProperty(Brixx.modules.planner.data.timelines[key].groups, [group]);
                    fullRebuild = true;
                }

                // Redraw the timeline.
                Brixx.modules.planner.timelines[key].redraw();
            });

            // Whether a full rebuild is required.
            if (fullRebuild) {
                Brixx.modules.planner.rebuildTimelines(forceFullRebuild || false);
            }
        },

        ajaxBrixxRequest: (url, data, showLoader, method) => {
            showLoader = showLoader || false;
            method = method || 'POST';

            const $loader = $('#planner-wrap > .ajax-loader');
            if (showLoader) {
                $loader.removeClass('hide');
            }

            $.ajax({...{
                timeout: Brixx.settings.ajaxTimeout,
                retries: Brixx.settings.ajaxRetries,
                retriesInterval: Brixx.settings.ajaxRetriesInterval,
                method: method,
                type: method,
                url: url
            }, ...((method === 'POST' && data) ? {
                enctype: 'multipart/form-data',
                contentType: false,
                processData: false,
                data: data
            } : {})})
                .done(responseData => {
                    Brixx.uiAjaxResponseHandler(responseData);
                })
                .fail((responseData, statusText) => {
                    Brixx.uiAjaxResponseHandler(responseData, null, statusText);
                })
                .always(() => {
                    $loader.addClass('hide');
                });
        },

        /**
         * Adds a team member to the given group.
         *
         * A target group has the `teamDropUrl` property, which holds
         * the URL to the add controller action. This action expects a
         * `teamMemberId` FormData value with the ID of the team member to
         * be added.
         *
         * @param {object} targetGroup
         *   The target group definition.
         * @param {number} teamMemberId
         *   The team member ID.
         */
        teamDropApply: (targetGroup, teamMemberId) => {
            if (!targetGroup || typeof targetGroup !== 'object' || typeof targetGroup.teamDropUrl === 'undefined') {
                return;
            }

            const data = new FormData();
            data.append('teamMemberId', teamMemberId);

            Brixx.modules.planner.ajaxBrixxRequest(targetGroup.teamDropUrl, data, true);
        },

        /**
         * Applies BRIXX modules attachments and adapts timeline labels according to their range.
         */
        timelinesChangedHandler: () => {
            $.each(Brixx.modules.planner.timelines, (currentKey, timeline) => {
                const range = timeline.getWindow();
                const startDate = new Date(range.start);
                const endDate = new Date(range.end);

                // Update download links.
                $('.planner-print-link').each((index, linkElement) => {
                    const $linkElement = $(linkElement);
                    let href = $linkElement.attr('href');
                    href = brixxUtils.addUrlParameter(href, 'startDate', brixxUtils.dateFormat(startDate, 'X'));
                    href = brixxUtils.addUrlParameter(href, 'endDate', brixxUtils.dateFormat(endDate, 'X'));
                    $linkElement.attr('href', href);
                });

                const diffTime = Math.abs(endDate - startDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                const longLabels = diffDays <= 90;

                const $longWeekLabels = $('.vis-minor .week-label > span', timeline.dom.top);

                if (longLabels) {
                    $longWeekLabels.removeClass('hide');
                }
                else {
                    $longWeekLabels.addClass('hide');
                }

                Brixx.detachModules(timeline.dom.root, Brixx.settings);
                Brixx.attachModules(timeline.dom.root, Brixx.settings);
            });
        },

        /**
         * Initializes the timeline event listeners for the given timeline.
         *
         * @param {HTMLElement|jQuery} element
         *   The parent DOM element of the timeline.
         * @param {object} timeline
         *   The initialized timeline.
         * @param {String} key
         *   The timeline key in the data objects.
         */
        initTimelineEvents: (element, timeline, key) => {
            const rangeChangeHandler = event => {
                const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);

                if (!event.byUser) {
                    return;
                }

                if (typeof event.event.srcEvent !== 'undefined') {
                    Brixx.modules.planner.ignoreTimelineClick();
                }

                timelineKeys.forEach(currentKey => {
                    if (currentKey !== key) {
                        Brixx.modules.planner.timelines[currentKey].setWindow(event.start, event.end, {animation: false});
                    }
                });

                Brixx.modules.planner.options.start = new Date(event.start);
                Brixx.modules.planner.options.end = new Date(event.end);
            };

            // Run modules attachment after each change of the timeline
            // (e.g., adding dynamic elements from templates).
            timeline.on('changed', Brixx.modules.planner.timelinesChangedHandler);

            // Click event to add/remove timeline items.
            timeline.on('click', event => {
                // Do not use this handler, if it was temporarily disabled via
                // `Brixx.modules.planner.isIgnoreTimelineClick`, or a link, button
                // or any input element was clicked (any such elements should have
                // their own `click` handlers/native responses to a click).
                if (Brixx.modules.planner.isIgnoreTimelineClick || (typeof event.event.target !== 'undefined' && ($(event.event.target).is('a') || $(event.event.target).is('button') || $(event.event.target).is(':input')))) {
                    return;
                }

                if (Brixx.modules.planner.data.mode !== 'm') {
                    if (typeof Brixx.modules.planner.data.nav.current === 'undefined' || (event.what !== 'item' && event.what !== 'background' && event.what !== 'axis')) {
                        return;
                    }

                    const month = event.time.getMonth() + 1;
                    const url = Brixx.modules.planner.data.nav.current;
                    const urlParts = url.split('?');

                    Brixx.modules.planner.ajaxBrixxRequest(urlParts[0].replace(/\/+$/, '') + '/' + month + (typeof urlParts[1] !== 'undefined' ? '?' + urlParts[1] : ''), null, true, 'GET');

                    return;
                }

                if (!event.group) {
                    return;
                }

                // Get group information.
                const targetGroup = Brixx.modules.planner.groups[key].get(event.group);
                // Whether the target group was not found or it doesn't allow
                // to add items.
                if (!targetGroup || typeof targetGroup.addItem === 'undefined' || !targetGroup.addItem) {
                    return;
                }

                // Handle item add and remove actions.
                // Form data to be sent with any callbacks.
                const data = new FormData();
                // Whether an item was clicked.
                if (event.what === 'item' && event.item) {
                    // Determine the item.
                    const item = Brixx.modules.planner.items[key].get(event.item);
                    // Whether the item could be found in the timeline data
                    // and has a delete URL.
                    if (item && typeof item.deleteUrl !== 'undefined') {
                        // Trigger a callback to the delete URL.
                        Brixx.modules.planner.ajaxBrixxRequest(item.deleteUrl, data, true);
                    }
                }
                // Whether the timeline background was clicked, the event has
                // an empty item property, and the related group has a callback
                // URL to add single items.
                else if (event.what === 'background' && !event.item && typeof targetGroup.addItem.callbackUrl !== 'undefined') {
                    // Add the clicked day to the form data.
                    data.append('day', event.time.getDate());
                    // Trigger a callback to the single item add URL.
                    Brixx.modules.planner.ajaxBrixxRequest(targetGroup.addItem.callbackUrl, data, true);
                }
            });

            // Click event to add/remove timeline items.
            timeline.on('contextmenu', event => {
                // Whether an item was clicked, rather than the items background.
                if (event.item || event.what !== 'background') {
                    // We allow a dialog to add items on the background only. Bail out.
                    return;
                }

                // Prevent the browser context menu from opening.
                // This line may be commented out while developping (comes again
                // after determining the dialog URL).
                event.event.preventDefault();

                // Get group information.
                const targetGroup = Brixx.modules.planner.groups[key].get(event.group);
                // Whether the target group was not found or it doesn't allow
                // to add items via dialog
                if (!targetGroup || typeof targetGroup.addItem === 'undefined' || !targetGroup.addItem || typeof targetGroup.addItem.dialogUrl === 'undefined') {
                    return;
                }

                // Get a jQuery object of the planner modal dialog.
                const $modal = $('#popup-modal-planner-item');
                // Whether there's no such dialog on the current page.
                if (!$modal.length) {
                    // We can't open a dialog without the modal element. Bail out.
                    return;
                }

                // Prevent the browser context menu from opening.
                event.event.preventDefault();

                // Initialize the modal dialog element with the current
                // item data:
                const $form = $modal.find('form');
                if ($form.length > 0) {
                    $form.attr('action', targetGroup.addItem.dialogUrl);
                    $('.planner-project-name', $form).html(targetGroup.projectName);
                    $('.planner-project-member', $form).html(targetGroup.content);
                    $('.planner-project-date', $form).html(brixxUtils.dateFormat(event.time));
                    $('[name="year"]', $form).val(event.time.getFullYear());
                    $('[name="month"]', $form).val(event.time.getMonth() + 1);
                    $('[name="day"]', $form).val(event.time.getDate());
                    $('[name="hours"]', $form).val(0);
                    $('[type="submit"]', $form).removeAttr('disabled');
                }

                // Show the dialog.
                $modal.foundation('open');

                // Set focus to the hours input field.
                if ($form.length > 0) {
                    $('[name="hours"]', $form).focus();
                }
            });

            timeline.on('rangechange', rangeChangeHandler);
            timeline.on('rangechanged', rangeChangeHandler);

            // Turn off the internal hammer tap event listener.
            timeline.itemSet.groupHammer.off('tap');

            // Use custom hammer tap event listener.
            timeline.itemSet.groupHammer.on('tap', function (event) {
                let $target = $(event.target);
                let $actions = $target.closest('.planner-group-actions, .no-collapse');

                if ($target.hasClass('.no-collapse') || $actions.length > 0) {
                    // Stop event propagation to prevent vis event handlers
                    // from kicking in.
                    event.stopPropagation();
                    // Set focus and trigger click for input elements.
                    if ($target.is(':input')) {
                        $target.trigger('focus');
                        $target.trigger('click');
                    }
                }
                else {
                    // Forward the event to the vis event handler.
                    timeline.itemSet._onGroupClick(event);
                }
            });

            // Turn off the internal group hammer pan event listeners.
            timeline.itemSet.groupHammer.off('panstart panmove panend pancancel');

            // Pan start.
            timeline.itemSet.groupHammer.on('panstart', Brixx.modules.planner.groupDragStart(key));

            // Update pan indicator position.
            timeline.itemSet.groupHammer.on('panmove', Brixx.modules.planner.groupDragMove(key));

            // Cancel panning.
            timeline.itemSet.groupHammer.on('pancancel', Brixx.modules.planner.groupDragCancel(key));

            // Finish panning.
            timeline.itemSet.groupHammer.on('panend', Brixx.modules.planner.groupDragEnd(key));
        },

        /**
         * Planner timeline default options.
         */
        options: {},

        updateTimelineRangeDuration: duration => {
            const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);

            let first = true;
            let endDate;
            let startDate = new Date(Brixx.modules.planner.options.start.valueOf());

            if (startDate.valueOf() + duration <= Brixx.modules.planner.options.max.valueOf()) {
                endDate = new Date(startDate.valueOf() + duration);
            }
            else {
                endDate = new Date(Brixx.modules.planner.options.max.valueOf());
                startDate = new Date(endDate.valueOf() - duration);
            }

            timelineKeys.forEach(currentKey => {
                if (typeof Brixx.modules.planner.timelines[currentKey] !== 'undefined') {
                    if (first) {
                        const currentRange = Brixx.modules.planner.timelines[currentKey].getWindow();
                        startDate = new Date(currentRange.start.valueOf());
                        if (startDate.valueOf() + duration <= Brixx.modules.planner.options.max.valueOf()) {
                            endDate = new Date(startDate.valueOf() + duration);
                        }
                        else {
                            endDate = new Date(Brixx.modules.planner.options.max.valueOf());
                            startDate = new Date(endDate.valueOf() - duration);
                        }
                        first = false;
                    }

                    Brixx.modules.planner.timelines[currentKey].setWindow(new Date(startDate.valueOf()), new Date(endDate.valueOf()), {animation: false});
                    Brixx.modules.planner.timelines[currentKey].redraw();
                }
            });
            Brixx.modules.planner.options.start = new Date(startDate.valueOf());
            Brixx.modules.planner.options.end = new Date(endDate.valueOf());
        },

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   BRIXX JS settings.
         */
        attach: (context, settings) => {
            $('script[type="application/json"][data-brixx-selector="brixx-planner-data-json"]', context).once('planner-data-init').each((index, element) => {
                Brixx.modules.planner.data = JSON.parse($(element).text());

                const printMode = typeof Brixx.modules.planner.data.print !== 'undefined';
                let date;
                let firstDay = (typeof Brixx.modules.planner.data.startDate !== 'undefined') ? new Date(Brixx.modules.planner.data.startDate) : null;
                let lastDay = (typeof Brixx.modules.planner.data.endDate !== 'undefined') ? new Date(Brixx.modules.planner.data.endDate) : null;

                if (Brixx.modules.planner.data.mode === 'y') {
                    date = (typeof Brixx.modules.planner.data.year !== 'undefined') ? new Date(Brixx.modules.planner.data.year, 0, 1) : new Date();
                }
                else {
                    date = (typeof Brixx.modules.planner.data.year !== 'undefined' && typeof Brixx.modules.planner.data.month !== 'undefined') ? new Date(Brixx.modules.planner.data.year, Brixx.modules.planner.data.month - 1, 1) : new Date();
                }

                if (!firstDay) {
                    firstDay = (Brixx.modules.planner.data.mode === 'y') ? new Date(date.getFullYear(), date.getMonth(), 1) : new Date(date.getFullYear(), date.getMonth(), 1);
                }
                firstDay.setHours(0, 0, 0, 0);

                if (!lastDay) {
                    lastDay = (Brixx.modules.planner.data.mode === 'y') ? new Date(date.getFullYear(), date.getMonth() + 12, 0) : new Date(date.getFullYear(), date.getMonth() + 1, 0);
                }
                lastDay.setHours(0, 0, 0, 0);

                Brixx.modules.planner.options = {
                    autoResize: true,
                    min: new Date(firstDay.valueOf() - 1),
                    start: new Date(firstDay.valueOf() - 1),
                    // Stay one minute short of the actual end of the month.
                    // This seems to prevent the month label from disappearing,
                    // if the visible timeline range is on its right end.
                    max: new Date(1000 * 59 * 60 * 24 + lastDay.valueOf()),
                    end: new Date(1000 * 59 * 60 * 24 + lastDay.valueOf()),
                    editable: {
                        add: false,
                        remove: false,
                        updateGroup: false,
                        updateTime: false,
                        overrideItems: false
                    },
                    format: {
                        majorLabels: (date, scale, step) => Brixx.modules.planner.axisTemplate('axis_navigation', {
                            nav: Brixx.modules.planner.data.nav,
                            yearLabel: brixxUtils.dateFormat(date.toDate(), (Brixx.modules.planner.data.mode === 'y') ? 'YY' : 'YYYY'),
                            monthLabel: brixxUtils.dateFormat(date.toDate(), 'MMM'),
                            year: date.toDate().getYear(),
                            month: date.toDate().getMonth() + 1,
                            mode: Brixx.modules.planner.data.mode,
                            date: date,
                            scale: scale,
                            step: step
                        }),
                        minorLabels: (date, scale, step) => Brixx.modules.planner.axisTemplate('axis_minor', {
                            nav: Brixx.modules.planner.data.nav,
                            dayLabel: date.toDate().getDate(),
                            dayOfWeek: date.toDate().getDay(),
                            yearLabel: brixxUtils.dateFormat(date.toDate(), (Brixx.modules.planner.data.mode === 'y') ? 'YY' : 'YYYY'),
                            monthLabel: brixxUtils.dateFormat(date.toDate(), 'MMM'),
                            weekLabel: brixxUtils.dateFormat(date.toDate(), 'W'),
                            year: date.toDate().getYear(),
                            month: date.toDate().getMonth() + 1,
                            mode: Brixx.modules.planner.data.mode,
                            date: date,
                            scale: scale,
                            step: step
                        })
                    },
                    groupEditable: (!printMode && Brixx.modules.planner.data.mode === 'm' ? {
                        add: true,
                        remove: true,
                        order: true
                    } : false),
                    groupHeightMode: 'fixed',
                    groupTemplate: Brixx.modules.planner.template,
                    horizontalScroll: false,
                    locale: Brixx.settings.language,
                    margin: {
                        item: {
                            horizontal: 0,
                            vertical: 0
                        },
                        axis: 5
                    },
                    minHeight: '150px',
                    moment: moment,
                    moveable: !printMode,
                    multiselect: false,
                    showCurrentTime: false,
                    showWeekScale: true,
                    stack: false,
                    template: Brixx.modules.planner.template,
                    timeAxis: {
                        scale: Brixx.modules.planner.data.mode === 'm' ? 'day' : 'week',
                        step: 1
                    },
                    type: 'range',
                    orientation: 'top',
                    verticalScroll: !printMode,
                    zoomable: !printMode,
                    zoomKey: 'ctrlKey',
                    zoomMin: 7 * 24 * 60 * 60 * 1000,
                    xss: {
                        disabled: true
                    }
                };

                if (!printMode) {
                    const fullMonthDuration = Brixx.modules.planner.options.max.valueOf() - Brixx.modules.planner.options.min.valueOf();
                    const mediaQuerySmallListener = event => {
                        if (event.matches) {
                            Brixx.modules.planner.updateTimelineRangeDuration(1000 * 60 * 60 * 24 * 7);
                            $('#planner-container').addClass('planner-groups-collapsed');
                        }
                    };
                    const mediaQueryMediumListener = event => {
                        if (event.matches) {
                            Brixx.modules.planner.updateTimelineRangeDuration(1000 * 60 * 60 * 24 * 14);
                            $('#planner-container').removeClass('planner-groups-collapsed');
                        }
                    };
                    const mediaQueryFullListener = event => {
                        if (event.matches) {
                            Brixx.modules.planner.updateTimelineRangeDuration(fullMonthDuration);
                            $('#planner-container').removeClass('planner-groups-collapsed');
                        }
                    };

                    mediaQuerySmall.addListener(mediaQuerySmallListener);
                    mediaQueryMedium.addListener(mediaQueryMediumListener);
                    mediaQueryFull.addListener(mediaQueryFullListener);

                    mediaQueryMediumListener(mediaQueryMedium);
                    mediaQuerySmallListener(mediaQuerySmall);
                }
            });

            if (typeof Brixx.modules.planner.data.timelines !== 'undefined') {
                const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);
                timelineKeys.forEach(key => {
                    const timelineInfo = Brixx.modules.planner.data.timelines[key];
                    $(timelineInfo.wrapper, context).once('plannerTimelineInit').each((index, element) => {
                        // Define the visible month date range.
                        var today = new Date();
                        today.setHours(0, 0, 0, 0);

                        // Specify options.
                        let options = {...Brixx.modules.planner.options, ...(timelineInfo.options || {})};

                        if (typeof timelineInfo.groupSort !== 'undefined' && timelineInfo.groupSort) {
                            options = {...options, ...{
                                groupOrder: Array.isArray(timelineInfo.groupSort) ? (Brixx.modules.planner.groupSortNested(timelineInfo.groupSort)) : (Brixx.modules.planner.groupSort(timelineInfo.groupSort))
                            }};
                        }

                        // Create datasets.
                        Brixx.modules.planner.groups[key] = new DataSet(timelineInfo.groups);
                        Brixx.modules.planner.items[key] = new DataSet(timelineInfo.items);

                        // Whether the planner timeline(s) shall be printed.
                        if (typeof Brixx.modules.planner.data.print !== 'undefined') {
                            // Printing timelines requires some tweaks: The rendered HTML markup
                            // of timelines doesn't cope well with the chunking of PagedJs. Therefore,
                            // we have to do the chunking manually. This is what's happening in the
                            // following code. We create multiple timelines with a maximum row
                            // count of `Brixx.modules.planner.data.printRowMax`.
                            const groupsPerPage = Brixx.modules.planner.data.printRowMax || 15;

                            // Do an initial sort of the groups, as it would be done by the
                            // vis-timeline code.
                            const topLevelGroups = Brixx.modules.planner.groups[key].get({
                                order: Array.isArray(timelineInfo.groupSort) ? (Brixx.modules.planner.groupSortNested(timelineInfo.groupSort)) : (Brixx.modules.planner.groupSort(timelineInfo.groupSort)),
                                filter: function (group) {
                                    return group.id !== 'timeline-empty' && (typeof group.treeLevel === 'undefined' || group.treeLevel === 1);
                                }
                            });

                            // Split the timeline into multiple timelines. (One per page.)
                            let pages = {};
                            let pageCount = 0;
                            let groupCount = 0;

                            $.each(topLevelGroups, (index, topLevelGroup) => {
                                // Create a copy of the top level group to account
                                // for partial nested groups.
                                let topLevelGroupCopy = {...topLevelGroup, ...{
                                    nestedGroups: []
                                }};

                                /**
                                 * Adds a group to a print page cluster.
                                 *
                                 * @param {object} group
                                 *   The group to add to the page.
                                 */
                                const addPageGroup = group => {
                                    if ((groupCount % groupsPerPage) === 0) {
                                        pageCount++;
                                        pages[key + '-page-' + pageCount] = {
                                            key: key,
                                            page: pageCount,
                                            groupIds: [],
                                            groups: [],
                                            items: [],
                                            options: {...options}
                                        };
                                        if (group.id !== topLevelGroup.id) {
                                            groupCount++;
                                            topLevelGroupCopy = {...topLevelGroup, ...{
                                                nestedGroups: []
                                            }};
                                            pages[key + '-page-' + pageCount].groupIds.push(topLevelGroupCopy.id);
                                            pages[key + '-page-' + pageCount].groups.push(topLevelGroupCopy);
                                        }
                                    }
                                    groupCount++;
                                    pages[key + '-page-' + pageCount].groupIds.push(group.id);
                                    pages[key + '-page-' + pageCount].groups.push(group);
                                    if (group.id !== topLevelGroup.id) {
                                        topLevelGroupCopy.nestedGroups.push(group.id);
                                    }
                                };

                                addPageGroup(topLevelGroupCopy);
                                const nestedGroups = Brixx.modules.planner.groups[key].get({
                                    order: Array.isArray(timelineInfo.groupSort) ? (Brixx.modules.planner.groupSortNested(timelineInfo.groupSort)) : (Brixx.modules.planner.groupSort(timelineInfo.groupSort)),
                                    filter: function (group) {
                                        return (typeof group.treeLevel === 'undefined' || group.treeLevel > 1) && (topLevelGroup.nestedGroups || []).includes(group.id);
                                    }
                                });
                                if (nestedGroups) {
                                    $.each(nestedGroups, (i, nestedGroup) => addPageGroup(nestedGroup));
                                }
                            });

                            // Initialize the timelines.
                            $.each(pages, (index, page) => {
                                let timeline;

                                // Ensure only items for the current timeline are used.
                                page.groups = new DataSet(page.groups);
                                page.items = new DataSet(Brixx.modules.planner.items[key].get({
                                    filter: function (item) {
                                        return page.groupIds.includes(item.group);
                                    }
                                }) || []);

                                // Whether the last timeline is to be initialized.
                                if (page.page === pageCount) {
                                    // After initial draw, mark JS as completed and the page as
                                    // ready for formatting with PagedJs.
                                    page.options.onInitialDrawComplete = () => {
                                        Brixx.modules.planner.timelinesChangedHandler();

                                        if (typeof window.printReady === 'function') {
                                            window.printReady();
                                        }
                                        else {
                                            window.printReady = true;
                                        }
                                    };
                                }

                                // Indicate previous days using a background item.
                                if (today.valueOf() > page.options.start.valueOf()) {
                                    page.items.add({
                                        type: 'background',
                                        start: new Date(page.options.start),
                                        end: new Date((today.valueOf() - 1) <= page.options.end.valueOf() ? today.valueOf() - 1 : page.options.end.valueOf()),
                                        style: 'background-color: rgba(225, 225, 225, .25); pointer-events: none;'
                                    });
                                }

                                // Indicate holidays.
                                if (typeof Brixx.modules.planner.data.holidays !== 'undefined') {
                                    let holidayStart;
                                    let holidayEnd;

                                    $.each(Brixx.modules.planner.data.holidays, (index, holiday) => {
                                        holidayStart = new Date(holiday.start);
                                        holidayStart.setHours(0, 0, 0, 0);
                                        holidayEnd = new Date(holiday.end);
                                        holidayEnd.setHours(23, 59, 59, 0);
                                        page.items.add({
                                            type: 'background',
                                            start: holidayStart,
                                            end: holidayEnd,
                                            style: 'background-color:' + holiday.color + ';pointer-events: none;'
                                        });
                                    });
                                }

                                // Create actual timeline.
                                let $pageElement = $('<div>').addClass('planner-print-page');
                                $(element).append($pageElement);
                                timeline = new Timeline($pageElement[0], page.items, page.groups, page.options);
                                Brixx.modules.planner.timelines[key + '-page-' + page.page] = timeline;
                            });

                            Brixx.attachModules(element, settings);
                        }
                        // Regular screen output.
                        else {
                            // Indicate previous days using a background item.
                            if (today.valueOf() > options.start.valueOf()) {
                                Brixx.modules.planner.items[key].add({
                                    type: 'background',
                                    start: new Date(options.start),
                                    end: new Date((today.valueOf() - 1) <= options.end.valueOf() ? today.valueOf() - 1 : options.end.valueOf()),
                                    style: 'background-color: rgba(225, 225, 225, .25); pointer-events: none;'
                                });
                            }

                            // Indicate holidays.
                            if (typeof Brixx.modules.planner.data.holidays !== 'undefined') {
                                let holidayStart;
                                let holidayEnd;

                                $.each(Brixx.modules.planner.data.holidays, (index, holiday) => {
                                    holidayStart = new Date(holiday.start);
                                    holidayStart.setHours(0, 0, 0, 0);
                                    holidayEnd = new Date(holiday.end);
                                    holidayEnd.setHours(23, 59, 59, 0);
                                    Brixx.modules.planner.items[key].add({
                                        type: 'background',
                                        start: holidayStart,
                                        end: holidayEnd,
                                        title: holiday.title,
                                        style: 'background-color:' + holiday.color + ';pointer-events: none;'
                                    });
                                });
                            }

                            // Create actual timeline.
                            const timeline = new Timeline(element, Brixx.modules.planner.items[key], Brixx.modules.planner.groups[key], options);
                            Brixx.modules.planner.timelines[key] = timeline;
                            // Bind event handlers for drag'n'drop, editing items, and so on.
                            Brixx.modules.planner.initTimelineEvents(element, Brixx.modules.planner.timelines[key], key);
                        }
                    });
                });
            }

            $('.vis-label.expanded.no-collapse').removeClass('expanded');

            $('a.planner-btn-modal[data-modal-id]', context).once('plannerModal').on('click.plannerModal tap.plannerModal', event => {
                const $modal = $('#' + $(event.currentTarget).data('modal-id'));
                if (!$modal.length) {
                    return;
                }

                // Initialize the modal dialog element for a new form:
                // Detach any event bindings from the dialog elements.
                Brixx.detachModules($modal);

                $('#popup-modal-planner-content', $modal).empty().append($('<div class="ajax-loader grid-x align-middle"><div class="cell text-center"><p>' + Translator.translate('ui.planner.loading-form') + '</p><img src="/static/assets/img/form-loader.gif" alt="loader"></div></div>'));

                // Show the dialog.
                $modal.foundation('open');
            });

            $('.modal-popup .form-cancel').once('plannerModalClose').on('click', Brixx.modules.planner.closePlannerModals);

            $('#planner-team-wrap .planner-timeline-label', context).once('collapseMembersToggle').on('click', event => {
                const $wrapper = $('#planner-container');
                if ($wrapper.hasClass('planner-team-collapsed')) {
                    $wrapper.removeClass('planner-team-collapsed');
                }
                else {
                    $wrapper.addClass('planner-team-collapsed');
                }
            });

            $('#planner-wrap .planner-timeline-label', context).once('collapseGroupsToggle').on('click', event => {
                const $wrapper = $('#planner-container');
                if ($wrapper.hasClass('planner-groups-collapsed')) {
                    $wrapper.removeClass('planner-groups-collapsed');
                }
                else {
                    $wrapper.addClass('planner-groups-collapsed');
                }

                const timelineKeys = Object.keys(Brixx.modules.planner.data.timelines);
                timelineKeys.forEach(currentKey => {
                    if (typeof Brixx.modules.planner.timelines[currentKey] !== 'undefined') {
                        Brixx.modules.planner.timelines[currentKey].redraw();
                    }
                });
            });

            $('input.add-member-name', context).once('submitOnEnter').on('keydown', event => {
                const $element = $(event.currentTarget);

                if (event.keyCode === 13 && !$element.is('[aria-expanded="true"]')) {
                    $element.closest('form').find('[type="submit"]').trigger('click');
                }
            });
            $('input.awesompletex.awe-planner-project', context).once('awesompletex').each((index, element) => {
                const $projectNameField = $(element);
                const elementName = $projectNameField.attr('name');
                const fieldNameGroup = brixxUtils.getFieldNameGroup(elementName);
                const dateStartFieldName = fieldNameGroup ? (fieldNameGroup + '[plan_wrapper][dateStart]') : 'plan_wrapper[dateStart]';
                const dateEndFieldName = fieldNameGroup ? (fieldNameGroup + '[plan_wrapper][dateEnd]') : 'plan_wrapper[dateEnd]';
                const hoursDefaultFieldName = fieldNameGroup ? (fieldNameGroup + '[plan_wrapper][hoursDefault]') : 'plan_wrapper[hoursDefault]';

                // Get the dependent form elements.
                const $form = $projectNameField.closest('form');
                const $dateStartField = $('[name="' + dateStartFieldName + '"]', $form);
                const $dateEndField = $('[name="' + dateEndFieldName + '"]', $form);
                const $hoursDefaultField = $('[name="' + hoursDefaultFieldName + '"]', $form);

                $projectNameField.list = $projectNameField.data('xlist') || [];

                if (typeof $projectNameField.list === 'string') {
                    let settingsList = Brixx.getSetting($projectNameField.list, null);
                    if (settingsList) {
                        $projectNameField.list = settingsList.map(listItem => brixxUtils.isObject(listItem) ? {...listItem} : listItem);
                    }
                }

                if ($projectNameField.list) {
                    const $label = $('label[for="' + $projectNameField.attr('id') + '"]', $form) || $projectNameField.next('label');

                    AwesompleteUtil.startCopy('#' + $projectNameField.attr('id'), 'dateEnd', (event, dataField) => {
                        if (!event.detail.length) {
                            return;
                        }
                        const value = event.detail[0][dataField];
                        $dateEndField.val(value ? brixxUtils.dateFormat(value, $dateEndField.data('date-format').toUpperCase()) : '');
                    });
                    AwesompleteUtil.startCopy('#' + $projectNameField.attr('id'), 'dateStart', (event, dataField) => {
                        if (!event.detail.length) {
                            return;
                        }
                        const value = event.detail[0][dataField];
                        $dateStartField.val(value ? brixxUtils.dateFormat(value, $dateStartField.data('date-format').toUpperCase()) : '');
                    });
                    AwesompleteUtil.startCopy('#' + $projectNameField.attr('id'), 'hoursDefault', (event, dataField) => {
                        if (!event.detail.length) {
                            return;
                        }
                        const value = event.detail[0][dataField];
                        Brixx.forms.setValue($hoursDefaultField, value);
                    });

                    // Initialize awesomplete.
                    $projectNameField.awcx = AwesompleteUtil.start('#' + $projectNameField.attr('id'), {
                        prepop: false
                    }, {
                        list: $projectNameField.list,
                        minChars: $projectNameField.data('minchars') || 2,
                        maxItems: $projectNameField.data('maxitems') || 20,
                        filter: AwesompleteUtil.filterContains
                    });

                    // Move the label back after the input field.
                    if ($label.length > 0) {
                        $label.detach().insertAfter($projectNameField);
                    }
                }
            });
            $('.planner-groups-collapse[data-timeline-key]', context).once('plannerGroupsCollapse').on('click', event => {
                event.preventDefault();
                const $element = $(event.currentTarget);
                const timelineKey = $element.data('timeline-key');
                if (typeof Brixx.modules.planner.groups[timelineKey] === 'undefined') {
                    return;
                }
                Brixx.modules.planner.groups[timelineKey].forEach(group => {
                    if (typeof group.treeLevel !== 'undefined' && group.treeLevel === 1) {
                        if (typeof group.nestedGroups !== 'undefined' && group.nestedGroups) {
                            group.nestedGroups.forEach(childId => {
                                Brixx.modules.planner.groups[timelineKey].update({
                                    id: childId,
                                    visible: false
                                });
                            });
                        }
                        Brixx.modules.planner.groups[timelineKey].update({
                            id: group.id,
                            showNested: false
                        });
                    }
                });
            });
            $('.planner-groups-expand[data-timeline-key]', context).once('plannerGroupsCollapse').on('click', event => {
                event.preventDefault();
                const $element = $(event.currentTarget);
                const timelineKey = $element.data('timeline-key');
                if (typeof Brixx.modules.planner.groups[timelineKey] === 'undefined') {
                    return;
                }
                Brixx.modules.planner.groups[timelineKey].forEach(group => {
                    if (typeof group.treeLevel !== 'undefined' && group.treeLevel === 1) {
                        Brixx.modules.planner.groups[timelineKey].update({
                            id: group.id,
                            showNested: true
                        });
                        if (typeof group.nestedGroups !== 'undefined' && group.nestedGroups) {
                            group.nestedGroups.forEach(childId => {
                                Brixx.modules.planner.groups[timelineKey].update({
                                    id: childId,
                                    visible: true
                                });
                            });
                        }
                    }
                });
            });
            $('input[type="checkbox"].planner-item-hours-default').once('plannerHideItemHours').each((index, element) => {
                const $checkbox = $(element);
                const toggleItemHoursVisibility = () => {
                    const $itemHoursFields = $checkbox.closest('form').find('.hours-value');
                    if ($checkbox.is(':checked')) {
                        $itemHoursFields.addClass('hide');
                    }
                    else {
                        $itemHoursFields.removeClass('hide');
                    }
                };
                $checkbox.on('click', () => toggleItemHoursVisibility());
                toggleItemHoursVisibility();
            });
        },
        detach: context => {
            $('a.planner-btn-modal[data-modal-id]', context).findOnce('plannerModal').each((index, element) => {
                const $element = $(element);
                $element.off('click.plannerModal tap.plannerModal').removeOnce('plannerModal');
            });
        }
    };

})(jQuery, Brixx, brixxUtils, Translator, Awesomplete, AwesompleteUtil, Theme, DataSet, Timeline, moment, window);
